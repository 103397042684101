import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { APIResult } from '../shared.model';
import { LookUpObject } from './look-up.model';

@Injectable({
  providedIn: 'root'
})
export class LookupService {

  /**
    * Private Variables
    */
  private _api_url: string = environment.API_URL + 'lookup/';

  private _is_gender_called: boolean = false;
  private _gender_data: BehaviorSubject<Array<LookUpObject>> = new BehaviorSubject<Array<LookUpObject>>(new Array<LookUpObject>())

  private _is_employment_type_called: boolean = false;
  private _employment_type: BehaviorSubject<Array<LookUpObject>> = new BehaviorSubject<Array<LookUpObject>>(new Array<LookUpObject>())

  private _is_work_position_called: boolean = false;
  private _work_position: BehaviorSubject<Array<LookUpObject>> = new BehaviorSubject<Array<LookUpObject>>(new Array<LookUpObject>())

  private _is_machine_status_called: boolean = false;
  private _machine_status: BehaviorSubject<Array<LookUpObject>> = new BehaviorSubject<Array<LookUpObject>>(new Array<LookUpObject>())

  private _is_media_type_called: boolean = false;
  private _media_type_data: BehaviorSubject<Array<LookUpObject>> = new BehaviorSubject<Array<LookUpObject>>(new Array<LookUpObject>())

  private _is_work_process_scheme_called: boolean = false;
  private _work_process_scheme: BehaviorSubject<Array<LookUpObject>> = new BehaviorSubject<Array<LookUpObject>>(new Array<LookUpObject>())

  private _is_member_type_called: boolean = false;
  private _member_type: BehaviorSubject<Array<LookUpObject>> = new BehaviorSubject<Array<LookUpObject>>(new Array<LookUpObject>())

  private _is_employee_work_position_called: boolean = false;
  private _employee_work_position: BehaviorSubject<Array<LookUpObject>> = new BehaviorSubject<Array<LookUpObject>>(new Array<LookUpObject>())

  private _is_task_status_called: boolean = false;
  private _task_status: BehaviorSubject<Array<LookUpObject>> = new BehaviorSubject<Array<LookUpObject>>(new Array<LookUpObject>())


  private _is_pause_types_called: boolean = false;
  private _pause_types_list: BehaviorSubject<Array<LookUpObject>> = new BehaviorSubject<Array<LookUpObject>>(new Array<LookUpObject>())

  /**
    * Constructor
    *
    * @param { HttpClient } _httpClient
    *
    */
  constructor(private _httpClient: HttpClient) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
    * Function for handling errors
    *
    * @param { HttpErrorResponse } error
    *
    */
  private _handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code. The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
    * Function to GET Member Gender
    */
  public getMemberGender(): Observable<Array<LookUpObject>> {
    if (!this._is_gender_called) {
      return this._httpClient.get<APIResult<Array<LookUpObject>>>(this._api_url + 'member/gender').pipe(
        map((data: APIResult<Array<LookUpObject>>) => {
          this._is_gender_called = true;
          this._gender_data.next(data.response)
          return data.response;
        }),
        catchError(this._handleError)
      )
    } else {
        return this._gender_data;
    }
  }

  /**
    * Function to GET Employment Type
    */
   public getEmploymentType(): Observable<Array<LookUpObject>> {
    if (!this._is_employment_type_called) {
      return this._httpClient.get<APIResult<Array<LookUpObject>>>(this._api_url + 'employment/type').pipe(
        map((data: APIResult<Array<LookUpObject>>) => {
          this._is_employment_type_called = true;
          this._employment_type.next(data.response)
          return data.response;
        }),
        catchError(this._handleError)
      )
    } else {
        return this._employment_type;
    }
  }

  /**
    * Function to GET Work Process Scheme
    */
   public getWorkProcessScheme(): Observable<Array<LookUpObject>> {
    if (!this._is_work_process_scheme_called) {
      return this._httpClient.get<APIResult<Array<LookUpObject>>>(this._api_url + 'schemas').pipe(
        map((data: APIResult<Array<LookUpObject>>) => {
          this._is_work_process_scheme_called = true;
          this._work_process_scheme.next(data.response)
          return data.response;
        }),
        catchError(this._handleError)
      )
    } else {
        return this._work_process_scheme;
    }
  }

  /**
    * Function to GET Work Position
    */
   public getWorkPosition(): Observable<Array<LookUpObject>> {
    if (!this._is_work_position_called) {
      return this._httpClient.get<APIResult<Array<LookUpObject>>>('http://api.ekarta.techup.me/work/position/list').pipe(
        map((data: APIResult<Array<LookUpObject>>) => {
          this._is_work_position_called = true;
          this._work_position.next(data.response)
          return data.response;
        }),
        catchError(this._handleError)
      )
    } else {
        return this._work_position;
    }
  }

  /**
    * Function to GET Machine Status
    */
  public getMachineStatus(): Observable<Array<LookUpObject>> {
    if (!this._is_machine_status_called) {
      return this._httpClient.get<APIResult<Array<LookUpObject>>>(this._api_url + 'machine/status').pipe(
        map((data: APIResult<Array<LookUpObject>>) => {
          this._is_machine_status_called = true;
          this._machine_status.next(data.response)
          return data.response;
        }),
        catchError(this._handleError)
      )
    } else {
        return this._machine_status;
    }
  }

  /**
    * Function to GET Media Type
    */
  public getMediaType(): Observable<Array<LookUpObject>> {
    if (!this._is_media_type_called) {
      return this._httpClient.get<APIResult<Array<LookUpObject>>>(this._api_url + 'media/type').pipe(
        map((data: APIResult<Array<LookUpObject>>) => {
          this._is_media_type_called = true;
          this._media_type_data.next(data.response)
          return data.response;
        }),
        catchError(this._handleError)
      )
    } else {
        return this._media_type_data;
    }
  }

  /**
   * Function to GET Member Type
   */
  public getMemberType(): Observable<Array<LookUpObject>> {
    if(!this._is_member_type_called) {
      return this._httpClient.get<APIResult<Array<LookUpObject>>>(this._api_url + 'member/type').pipe(
        map((data: APIResult<Array<LookUpObject>>) => {
          this._is_member_type_called = true;
          this._member_type.next(data.response)
          return data.response;
        }),
        catchError(this._handleError)
      )
    } else {
      return this._member_type;
    }
  }

  /**
   * Function to GET Employee Work Positions
   */
  public getEmployeeWorkPositions(memberId: any): Observable<any> {
      return this._httpClient.get<APIResult<any>>(this._api_url + `work-positions/for-employee?memberId=${memberId}`).pipe(
        map((data: APIResult<any[]>) => {
          this._is_employee_work_position_called = true;
          this._employee_work_position.next(data.response)
          return data.response;
        }),
        catchError(this._handleError)
      )
  }

  /**
   * Function to GET Task Status
   */
   public getTaskStatus(): Observable<Array<LookUpObject>> {
    if(!this._is_task_status_called) {
      return this._httpClient.get<APIResult<Array<LookUpObject>>>(this._api_url + 'task/status').pipe(
        map((data: APIResult<Array<LookUpObject>>) => {
          this._is_task_status_called = true;
          this._task_status.next(data.response)
          return data.response;
        }),
        catchError(this._handleError)
      )
    } else {
      return this._task_status;
    }
  }

  /**
   * Function to GET Pause Types List
   */
   public getPauseTypes(): Observable<Array<LookUpObject>> {
    if(!this._is_pause_types_called) {
      return this._httpClient.get<APIResult<Array<LookUpObject>>>(this._api_url + 'employee/break/type').pipe(
        map((data: APIResult<Array<LookUpObject>>) => {
          this._is_pause_types_called = true;
          this._pause_types_list.next(data.response)
          return data.response;
        }),
        catchError(this._handleError)
      )
    } else {
      return this._pause_types_list;
    }
  }

}
